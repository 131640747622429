import { Navigate, useRoutes } from 'react-router-dom';

// routes
import LoginRoutes from './LoginRoutes';
import { useContext } from 'react';
import JWTContext from 'contexts/JWTContext';
import AgentRoutes from './AgentRouest';
import SuperAdminRoutes from './SuperAdminRoutes';
import { ROLE_AGENT, ROLE_SUPERADMIN, ROLE_ADMIN } from 'constant';
import FileCompareComponent from 'views/FileDiff';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user } = useContext(JWTContext);
    function SelectUserBaseRoutes(role) {
        let defaultRoutes = [];
        switch (role) {
            case ROLE_ADMIN:
                defaultRoutes = SuperAdminRoutes;
                break;
            case ROLE_SUPERADMIN:
                defaultRoutes = SuperAdminRoutes;
                break;
            case ROLE_AGENT:
                defaultRoutes = AgentRoutes;
                break;
            default:
                break;
        }
        return defaultRoutes;
    }
    const DefaultRoutes = SelectUserBaseRoutes(user?.role ?? null);

    return useRoutes([
        { path: '/', element: <Navigate to="/login" /> },
        {
            path: '/file-compare',
            element: <FileCompareComponent />
        },
        LoginRoutes,
        DefaultRoutes
    ]);
}

import React, { useRef, useState } from 'react';
import axios from 'axios';
import { Button, Grid, LinearProgress } from '@mui/material';

const FileCompareComponent = () => {
    const fileInputRef = useRef(null);
    const fileInputRef1 = useRef(null);
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleFile1Change = (event) => {
        const selectedFile = event.target.files[0];
        // Validate file type (accept only CSV)
        if (selectedFile.type !== 'text/csv') {
            alert('Please select a CSV file.');
            return;
        }
        // Validate file size (less than 20MB)
        if (selectedFile.size > 20 * 1024 * 1024) {
            alert('File size exceeds 20MB limit.');
            return;
        }
        setFile1(event.target.files[0]);
    };

    const handleFile2Change = (event) => {
        const selectedFile = event.target.files[0];
        // Validate file type (accept only CSV)
        if (selectedFile.type !== 'text/csv') {
            alert('Please select a CSV file.');
            return;
        }
        // Validate file size (less than 20MB)
        if (selectedFile.size > 20 * 1024 * 1024) {
            alert('File size exceeds 20MB limit.');
            return;
        }
        setFile2(event.target.files[0]);
    };

    const handleCompare = () => {
        if (loading) return;
        // Perform API call to compare files
        if (file1 && file2) {
            const formData = new FormData();
            formData.append('file1', file1);
            formData.append('file2', file2);

            setLoading(true);

            axios
                .post('https://compare.payouton.com/api/v1/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    setLoading(false);

                    setFile1(null);
                    setFile2(null);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = null;
                    }
                    if (fileInputRef1.current) {
                        fileInputRef1.current.value = null;
                    }
                    // Handle successful comparison
                    console.log('Comparison result:', response);

                    // Create a Blob object from the response
                    const blob = new Blob([response.data], { type: 'text/csv' });

                    // Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    document.body.appendChild(a);

                    // Create a URL pointing to the ObjectURL containing the blob
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = 'output.csv';
                    a.click();

                    // Clean up
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(a);
                    // You can further process the response here
                })
                .catch((error) => {
                    setLoading(false);
                    setFile1(null);
                    setFile2(null);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = null;
                    }
                    if (fileInputRef1.current) {
                        fileInputRef1.current.value = null;
                    }
                    // Handle error
                    alert(error.message);
                });
        } else {
            alert('Please upload both files before comparing.');
        }
    };

    return (
        <div style={{ margin: 10 }}>
            {loading && <LinearProgress />}
            <h2>File Comparison</h2>
            <Grid sx={{ mt: 4, ml: 1 }} container alignItems="center" spacing={2}>
                <input ref={fileInputRef} accept=".csv" type="file" onChange={handleFile1Change} />
                <input ref={fileInputRef1} accept=".csv" type="file" onChange={handleFile2Change} />
                <Button disabled={loading} size="small" variant="contained" sx={{ ml: '1rem' }} onClick={handleCompare}>
                    Compare
                </Button>
            </Grid>
        </div>
    );
};

export default FileCompareComponent;

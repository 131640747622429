// material-ui
import { useTheme } from '@mui/material/styles';

import logo from 'assets/images/logo.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return <img src={theme.palette.mode === 'dark' ? logo : logo} alt="Payouton" width="180" />;
};

export default Logo;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconMenu, IconBoxMultiple, IconCircleOff, IconCircle, IconBrandGravatar, IconShape, IconSettingsAutomation } from '@tabler/icons';

// constant
const icons = {
    IconMenu,
    IconBoxMultiple,
    IconCircleOff,
    IconCircle,
    IconBrandGravatar,
    IconShape,
    IconSettingsAutomation
};

// ==============================|| SUPPORT MENU ITEMS ||============================== //

const support = {
    id: 'support',
    title: <FormattedMessage id="support" />,
    icon: icons.IconMenu,
    type: 'group',
    children: [
        // {
        //     id: 'settlement',
        //     title: <FormattedMessage id="Settlements" />,
        //     type: 'item',
        //     url: '/support/settlement',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // },
        {
            id: 'payout credit',
            title: <FormattedMessage id="payout credit" />,
            type: 'item',
            url: '/support/payout-credit',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'report',
            title: <FormattedMessage id="report" />,
            type: 'item',
            url: '/support/reports',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'support logs',
            title: <FormattedMessage id="support logs" />,
            type: 'item',
            url: '/support/logs',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'request',
            title: <FormattedMessage id="Request" />,
            type: 'collapse',
            icon: icons.IconSitemap,
            children: [
                // {
                //     id: 'settlement1',
                //     title: <FormattedMessage id="Merchant Settlement" />,
                //     type: 'item',
                //     url: '/support/merchant',
                //     breadcrumbs: false
                // },
                // {
                //     id: 'settlement2',
                //     title: <FormattedMessage id="Affilate Settlement" />,
                //     type: 'item',
                //     url: '/support/affiliate',
                //     breadcrumbs: false
                // },
                {
                    id: 'payout',
                    title: <FormattedMessage id="Payout" />,
                    type: 'item',
                    url: '/support/payout-request',
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'webhooks',
            title: <FormattedMessage id="Webhooks" />,
            type: 'item',
            url: '/support/webhooks',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        // {
        //     id: 'customer-data',
        //     title: <FormattedMessage id="Customers" />,
        //     type: 'item',
        //     url: '/support/customer-data',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // },
        {
            id: 'tracker',
            title: <FormattedMessage id="File Tracker" />,
            type: 'collapse',
            icon: icons.IconSitemap,
            children: [
                {
                    id: 'upload-reports',
                    title: <FormattedMessage id="Payout Reports" />,
                    type: 'item',
                    url: '/tracker/upload-reports',
                    icon: icons.IconDeviceAnalytics,
                    breadcrumbs: false
                }
                // {
                //     id: 'bank-txn-reports',
                //     title: <FormattedMessage id="Bank Txn Reports" />,
                //     type: 'item',
                //     url: '/tracker/bank-txn-reports',
                //     icon: icons.IconDeviceAnalytics,
                //     breadcrumbs: false
                // }
            ]
        }

        // {
        //     id: 'pg',
        //     title: <FormattedMessage id="PG" />,
        //     type: 'item',
        //     url: '/support/pg',
        //     icon: icons.IconDeviceAnalytics,
        //     breadcrumbs: false
        // }
        // {
        //     id: 'menu-level',
        //     title: <FormattedMessage id="menu-level" />,
        //     type: 'collapse',
        //     icon: icons.IconMenu,
        //     children: [
        //         {
        //             id: 'menu-level-1.1',
        //             title: (
        //                 <>
        //                     <FormattedMessage id="level" /> 1
        //                 </>
        //             ),
        //             type: 'item',
        //             url: '#'
        //         },
        //         {
        //             id: 'menu-level-1.2',
        //             title: (
        //                 <>
        //                     <FormattedMessage id="level" /> 1
        //                 </>
        //             ),
        //             type: 'collapse',
        //             children: [
        //                 {
        //                     id: 'menu-level-2.1',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="level" /> 2
        //                         </>
        //                     ),
        //                     type: 'item',
        //                     url: '#'
        //                 },
        //                 {
        //                     id: 'menu-level-2.2',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="level" /> 2
        //                         </>
        //                     ),
        //                     type: 'collapse',
        //                     children: [
        //                         {
        //                             id: 'menu-level-3.1',
        //                             title: (
        //                                 <>
        //                                     <FormattedMessage id="level" /> 3
        //                                 </>
        //                             ),
        //                             type: 'item',
        //                             url: '#'
        //                         },
        //                         {
        //                             id: 'menu-level-3.2',
        //                             title: (
        //                                 <>
        //                                     <FormattedMessage id="level" /> 3
        //                                 </>
        //                             ),
        //                             type: 'item',
        //                             url: '#'
        //                         }
        //                     ]
        //                 }
        //             ]
        //         }
        //     ]
        // },
        // {
        //     id: 'menu-level-subtitle',
        //     title: <FormattedMessage id="menu-level-subtitle" />,
        //     caption: <FormattedMessage id="menu-level-subtitle-caption" />,
        //     type: 'collapse',
        //     icon: icons.IconBoxMultiple,
        //     children: [
        //         {
        //             id: 'sub-menu-level-1.1',
        //             title: (
        //                 <>
        //                     <FormattedMessage id="level" /> 1
        //                 </>
        //             ),
        //             caption: <FormattedMessage id="menu-level-subtitle-item" />,
        //             type: 'item',
        //             url: '#'
        //         },
        //         {
        //             id: 'sub-menu-level-1.2',
        //             title: (
        //                 <>
        //                     <FormattedMessage id="level" /> 1
        //                 </>
        //             ),
        //             caption: <FormattedMessage id="menu-level-subtitle-collapse" />,
        //             type: 'collapse',
        //             children: [
        //                 {
        //                     id: 'sub-menu-level-2.1',
        //                     title: (
        //                         <>
        //                             <FormattedMessage id="level" /> 2
        //                         </>
        //                     ),
        //                     caption: <FormattedMessage id="menu-level-subtitle-sub-item" />,
        //                     type: 'item',
        //                     url: '#'
        //                 }
        //             ]
        //         }
        //     ]
        // },
        // {
        //     id: 'disabled-menu',
        //     title: <FormattedMessage id="disabled-menu" />,
        //     type: 'item',
        //     url: '#',
        //     icon: icons.IconCircleOff,
        //     disabled: true
        // },
        // {
        //     id: 'oval-chip-menu',
        //     title: <FormattedMessage id="oval-chip-menu" />,
        //     type: 'item',
        //     url: '#',
        //     icon: icons.IconCircle,
        //     chip: {
        //         label: '9',
        //         color: 'primary'
        //     }
        // },
        // {
        //     id: 'user-chip-menu',
        //     title: <FormattedMessage id="avatar" />,
        //     type: 'item',
        //     url: '#',
        //     icon: icons.IconBrandGravatar,
        //     chip: {
        //         label: <FormattedMessage id="coded" />,
        //         color: 'primary',
        //         avatar: <FormattedMessage id="c" />,
        //         size: 'small'
        //     }
        // },
        // {
        //     id: 'outline-chip-menu',
        //     title: <FormattedMessage id="outlined" />,
        //     type: 'item',
        //     url: '#',
        //     icon: icons.IconShape,
        //     chip: {
        //         label: <FormattedMessage id="outlined" />,
        //         variant: 'outlined',
        //         color: 'primary'
        //     }
        // }
    ]
};

export const AgentSupport = {
    id: 'support',
    title: <FormattedMessage id="support" />,
    icon: icons.IconMenu,
    type: 'group',
    children: [
        {
            id: 'Reports',
            title: <FormattedMessage id="Reports" />,
            type: 'item',
            url: '/support/reports',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        }
    ]
};

export default support;

import React, { useEffect } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    CardContent,
    TextField,
    Tooltip,
    Autocomplete,
    IconButton,
    Stack,
    Switch,
    InputAdornment,
    MenuItem
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';
import SearchIcon from '@mui/icons-material/Search';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { fetchActiveMerchants, getCustomerOrderData } from 'utils/api';
import { useDispatch } from 'react-redux';
import { Delete, Edit, RefreshOutlined } from '@mui/icons-material';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import Loader from 'ui-component/Loader';
import AddData from './AddData';
// import ConfirmationModal from 'ui-component/Modal';
import axiosServices from 'utils/axios';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { ISTDate, ROLE_ADMIN, ROLE_SUPERADMIN } from 'constant';
import ConfirmationModal from 'ui-component/Modal';
import useAuth from 'hooks/useAuth';
import axiosServicesNew from 'utils/axios_new';

// table header options
const headCells = [
    {
        id: 'id',
        numeric: false,
        label: 'Id',
        align: 'center'
    },
    {
        id: 'merchant',
        numeric: false,
        label: 'Merchant',
        align: 'left'
    },
    {
        id: 'Name',
        numeric: false,
        label: 'Name',
        align: 'left'
    },
    {
        id: 'playerMobileNumber',
        numeric: false,
        label: 'Mobile',
        align: 'left'
    },
    {
        id: 'playerEmail',
        numeric: true,
        label: 'Email',
        align: 'left'
    },
    {
        id: 'Amount',
        numeric: false,
        label: 'Amount',
        align: 'left'
    },
    {
        id: 'transactionCount',
        numeric: false,
        label: 'Txn Count',
        align: 'left'
    },
    {
        id: 'level',
        numeric: false,
        label: 'Level',
        align: 'left'
    },
    {
        id: 'date',
        numeric: false,
        label: 'Created Date',
        align: 'left'
    },
    {
        id: 'Blocked',
        numeric: false,
        label: 'Blocked',
        align: 'center'
    }
];

const currentDate = ISTDate;

const CustomerData = () => {
    const dispatch = useDispatch();
    const { user } = useAuth();

    const [rows, setRows] = React.useState([]);
    const [merchants, setMerchants] = React.useState([]);
    const [allData, setAllData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [deleteTxn, setDeleteTxn] = React.useState(false);
    const [resetOpen, setResetOpen] = React.useState(false);

    const [merchant, setMerchant] = React.useState();
    const [player, setPlayer] = React.useState('');
    const [playerId, setPlayerId] = React.useState('');
    const [level, setLevel] = React.useState('');

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const [startDate, setStartDate] = React.useState(currentDate);
    const [endDate, setEndDate] = React.useState(currentDate);

    const fetchDataFromAPI = async (page, rowsPerPage) => {
        setLoading(true);
        try {
            const payload = {
                playerName: player,
                playerId,
                level,
                merchantId: merchant?.id ? [merchant?.id] : [],
                startDate,
                endDate
            };
            const result = await getCustomerOrderData(page || 0, rowsPerPage || 50, payload);
            setLoading(false);
            setAllData(result);
            setRows(result?.transactionList || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
        try {
            let result = await fetchActiveMerchants();
            result = result?.map((r) => ({
                id: r.merchantId || '',
                label: r.name || ''
            }));
            setMerchants(result);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchDataFromAPI(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI(page, rowsPerPage);
    };

    // const handleDeleteTxn = () => {
    //     setLoading(true);
    //     axiosServices
    //         .delete(`deletedSettledTxn/${deleteTxn?.manualSettledEntityId?.merchantId}/${deleteTxn?.manualSettledEntityId?.settlementId}`)
    //         .then((res) => {
    //             setLoading(false);
    //             dispatch(
    //                 openSnackbar({
    //                     open: true,
    //                     message: 'Transaction Deleted',
    //                     variant: 'alert',
    //                     alert: {
    //                         color: 'success'
    //                     },
    //                     close: false
    //                 })
    //             );
    //             dispatch(closeSnackbar);
    //             setDeleteTxn(false);
    //             fetchDataFromAPI(merchant?.id, page, rowsPerPage);
    //         })
    //         .catch((e) => {
    //             setLoading(false);
    //             dispatch(
    //                 openSnackbar({
    //                     open: true,
    //                     message: e?.message || 'Error while deleting transaction',
    //                     variant: 'alert',
    //                     alert: {
    //                         color: 'error'
    //                     },
    //                     close: true
    //                 })
    //             );
    //             dispatch(closeSnackbar);
    //         });
    // };

    const onRefresh = () => {
        setRows([]);
        fetchDataFromAPI(page, rowsPerPage);
    };

    const handleStatus = () => {
        setLoading(true);
        axiosServices
            .put(`updateCustomerAuthorization`, {
                id: resetOpen?.id,
                isBlocked: !resetOpen?.isBlocked
            })
            .then((r) => {
                setLoading(false);
                setResetOpen(false);
                fetchDataFromAPI();
            })

            .catch((e) => {
                setLoading(false);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while updating status',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    const handleSearch = () => {
        fetchDataFromAPI(page, rowsPerPage);
    };

    const clearSearch = () => {
        window.location.reload();
    };

    const handleGenerateReport = () => {
        setLoading(true);
        try {
            const payload = {
                playerName: player,
                playerId,
                level,
                merchantId: merchant?.id ? [merchant?.id] : [],
                startDate,
                endDate
            };
            axiosServicesNew
                .post(`customerOrderFilterReport`, payload)
                .then((res) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: res?.data?.data || 'Report Generation In progress',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: true
                        })
                    );
                })
                .catch((e) => {
                    setLoading(false);
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Something went wrong',
                            variant: 'alert',
                            alert: {
                                color: 'error'
                            },
                            close: true
                        })
                    );
                });
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    return (
        <MainCard
            title={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>Customer Data</div>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button size="small" variant="contained" sx={{ mr: '1rem' }} onClick={handleGenerateReport}>
                            Generate Report
                        </Button>
                        <Tooltip title="Refresh">
                            <RefreshOutlined onClick={onRefresh} style={{ cursor: 'pointer' }} />
                        </Tooltip>
                    </div>
                </div>
            }
            content={false}
        >
            <Grid sx={{ mt: 2, mb: 1, ml: 3 }} container alignItems="center" spacing={1}>
                <Grid sx={{ mb: 2 }} item alignItems="center" spacing={1}>
                    <Autocomplete
                        id="merchantId"
                        className="merchantId-select"
                        name="merchantId"
                        options={merchants?.length ? merchants : []}
                        onChange={(e, value) => {
                            setMerchant(value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                onChange={(e, value) => {
                                    setMerchant(value);
                                }}
                                size="small"
                                style={{ width: 140 }}
                                label="Merchant"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                </Grid>
                <Grid sx={{ mb: 2 }} item>
                    <TextField
                        sx={{ width: 140, mr: 2, ml: 2 }}
                        onChange={(e) => setPlayerId(e.target.value)}
                        type="string"
                        placeholder="Player Id"
                        value={playerId}
                        size="small"
                    />
                </Grid>
                <Grid sx={{ mb: 2 }} item>
                    <TextField
                        sx={{ width: 140, mr: 2, ml: 2 }}
                        onChange={(e) => setPlayer(e.target.value)}
                        type="string"
                        placeholder="Player Name"
                        value={player}
                        size="small"
                    />
                </Grid>

                <Grid sx={{ mb: 2 }} item>
                    <TextField
                        fullWidth
                        sx={{ width: 120 }}
                        size="small"
                        select
                        id="level"
                        name="level"
                        label="Select Level"
                        value={level}
                        onChange={(e) => setLevel(e.target.value)}
                    >
                        <MenuItem value="Level1">Level1</MenuItem>
                        <MenuItem value="Level2">Level2</MenuItem>
                        <MenuItem value="Level3">Level3</MenuItem>
                        <MenuItem value="Level4">Level4</MenuItem>
                    </TextField>
                </Grid>
                <Grid sx={{ mb: 0 }} spacing={1} item justifyContent="start" alignItems="center">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            inputFormat="YYYY/MM/DD"
                            value={startDate}
                            onChange={(value) => {
                                const dt = new Date(value);
                                const stDate = dayjs(dt).format('YYYY-MM-DD');
                                setStartDate(stDate);
                            }}
                            maxDate={ISTDate}
                            renderInput={(params) => <TextField sx={{ width: 160, mr: 1 }} size="small" {...params} />}
                            label="Start Date"
                        />
                        <DatePicker
                            inputFormat="YYYY/MM/DD"
                            value={endDate}
                            minDate={startDate}
                            maxDate={ISTDate}
                            onChange={(value) => {
                                const dt = new Date(value);
                                const enDate = dayjs(dt).format('YYYY-MM-DD');
                                setEndDate(enDate);
                            }}
                            renderInput={(params) => <TextField sx={{ width: 160, mr: 1, ml: 2 }} size="small" {...params} />}
                            label="End Date"
                        />
                    </LocalizationProvider>
                    <Button size="small" variant="contained" sx={{ mr: '1rem', ml: 2 }} onClick={handleSearch}>
                        Apply
                    </Button>
                    <Button size="small" variant="contained" sx={{ mr: '1rem', ml: 2 }} color="secondary" onClick={clearSearch}>
                        Clear
                    </Button>
                </Grid>
            </Grid>
            {loading && <Loader />}
            <TableContainer sx={{ height: '80vh', mt: 3 }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow hover>
                                    <TableCell align="center" component="th" id={labelId} scope="row">
                                        {row.playerId}
                                    </TableCell>
                                    <TableCell style={{ fontWeight: 500 }}>
                                        <div>{row.merchantId}</div>
                                    </TableCell>
                                    <TableCell>{row.playerName}</TableCell>
                                    <TableCell>{row.playerMobileNumber}</TableCell>
                                    <TableCell>{row.playerEmail}</TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>{row.transactionCount}</TableCell>
                                    <TableCell>{row.level}</TableCell>
                                    <TableCell>{row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}</TableCell>
                                    <TableCell align="center">
                                        <Stack
                                            onClick={() => setResetOpen(row)}
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <IconButton color="primary" size="small">
                                                <Switch checked={row.isBlocked} />
                                                {/* {row.isPayInEnable.toString()} */}
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                    {/* <TableCell align="center">
                                        <Tooltip placement="top" title="Edit">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <Edit onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip placement="top" title="Delete">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <Delete onClick={() => setDeleteTxn(row)} sx={{ fontSize: '1.1rem', color: 'red' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell> */}
                                </TableRow>
                            );
                        })}
                        {/* {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )} */}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* <ConfirmationModal
                open={deleteTxn}
                title="Delete Transaction"
                subTitle="Are you sure, you want to delete selected Transaction"
                handleClose={() => setDeleteTxn(false)}
                handleSubmit={handleDeleteTxn}
                loading={loading}
            /> */}

            {(user?.role === ROLE_SUPERADMIN || user?.role === ROLE_ADMIN) && (
                <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                    <ConfirmationModal
                        loading={loading}
                        open={resetOpen}
                        title={`${resetOpen?.isBlocked ? 'Unblock' : 'Block'}`}
                        subTitle={`Are you sure, you want to ${resetOpen?.isBlocked ? 'unblock' : 'block'} customer`}
                        handleClose={() => setResetOpen(false)}
                        handleSubmit={handleStatus}
                    />
                </Grid>
            )}

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 250]}
                component="div"
                count={allData?.totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default CustomerData;

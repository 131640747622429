import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Slide,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { Box, useTheme } from '@mui/system';
import { AVAILABLE_METHODS } from 'constant';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBanks } from 'store/slices/bank';
import { closeSnackbar, openSnackbar } from 'store/slices/snackbar';
import { fetchPayinMeta } from 'utils/api';
import axiosServices from 'utils/axios';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

// table header options
const headCells = [
    {
        id: 'name',
        label: 'Account Holder',
        align: 'left'
    },
    {
        id: 'account',
        label: 'Account No',
        align: 'left'
    },
    {
        id: 'UPI',
        label: 'UPI',
        align: 'left'
    },
    {
        id: 'Bank',
        label: 'Bank',
        align: 'left'
    },
    {
        id: 'creationMethod',
        label: 'Method',
        align: 'left'
    },
    {
        id: 'methods',
        label: 'Available Methods',
        align: 'left'
    },
    {
        id: 'level',
        label: 'Level',
        align: 'left'
    }
];

// ==============================|| MERCHANT BANKS ||============================== //

const PayInMeta = ({ id, setRefetch }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [rows, setRows] = React.useState([]);
    const [allRows, setAllRows] = React.useState([]);

    const [selectedBank, setSelectedBank] = React.useState(false);
    const [selectedMethod, setSelectedMethod] = React.useState();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const fetchDataFromAPI = async (id) => {
        try {
            const result = await fetchPayinMeta();
            setRows(result);
            setAllRows(result);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        fetchDataFromAPI(id);
    }, []);

    React.useEffect(() => {
        if (value) {
            const auto = allRows.filter((r) => r.creationMethod === 'AUTO');
            setRows(auto);
        } else {
            const auto = allRows.filter((r) => r.creationMethod !== 'AUTO');
            setRows(auto);
        }
    }, [value, allRows]);

    const handleAddMeta = () => {
        if (!selectedMethod) return;
        axiosServices
            .post('add/merchant/in/payin', { bankId: selectedBank.bankId, merchantId: id, availableMethod: selectedMethod })
            .then((res) => {
                console.log(res);
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Bank added successfully!',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
                setSelectedBank(false);
                setRefetch(Math.random());
            })
            .catch((e) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: e?.message || 'Error while adding bank',
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: true
                    })
                );
                dispatch(closeSnackbar);
            });
    };

    return (
        <div style={{ margin: '10px 20px' }}>
            <Box sx={{ marginBottom: 2, borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Manual" {...a11yProps(0)} />
                    <Tab label="Auto" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TableContainer>
                <Table aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell key={headCell.id} align={headCell.align} padding={headCell.disablePadding ? 'none' : 'normal'}>
                                    {headCell.label}
                                </TableCell>
                            ))}
                            <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                                <Typography variant="subtitle1" sx={{ color: theme.palette.mode === 'dark' ? 'grey.600' : 'grey.900' }}>
                                    Action
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index} hover>
                                <TableCell>{row.accountHolderName}</TableCell>
                                <TableCell>{row.accountNumber}</TableCell>
                                <TableCell>{row.upiId}</TableCell>
                                <TableCell>{row.bankName}</TableCell>
                                <TableCell>{row.creationMethod}</TableCell>
                                <TableCell>{row.availableMethods}</TableCell>
                                <TableCell>{row.level}</TableCell>
                                <TableCell align="center">
                                    <Button onClick={() => setSelectedBank(row)} variant="contained" size="small">
                                        Add Meta
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        {rows?.length === 0 && <div style={{ marginTop: 10, color: 'red' }}>No Data</div>}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={selectedBank}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setSelectedBank(false)}
                aria-labelledby="alert-dialog-slide-title1"
                aria-describedby="alert-dialog-slide-description1"
            >
                <>
                    <DialogTitle id="alert-dialog-slide-title1">{selectedBank?.accountHolderName}</DialogTitle>
                    <DialogContent style={{ display: 'flex', alignItems: 'center', margin: 10 }}>
                        <TextField
                            fullWidth
                            select
                            id="availableMethods"
                            name="availableMethods"
                            label="Select Method"
                            style={{ width: 200, marginTop: 10 }}
                            value={selectedMethod}
                            onChange={(e) => setSelectedMethod(e.target.value)}
                        >
                            {AVAILABLE_METHODS?.map((method) => {
                                if (selectedBank.availableMethods?.includes(method)) {
                                    return <MenuItem value={method}>{method}</MenuItem>;
                                }
                                return <></>;
                            })}
                        </TextField>
                    </DialogContent>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <Button
                            sx={{ color: theme.palette.error.dark, borderColor: theme.palette.error.dark }}
                            onClick={() => setSelectedBank(false)}
                            color="secondary"
                        >
                            Cancel
                        </Button>
                        <Button disabled={!selectedMethod} variant="contained" size="small" onClick={handleAddMeta}>
                            Add
                        </Button>
                    </DialogActions>
                </>
            </Dialog>
        </div>
    );
};

export default PayInMeta;

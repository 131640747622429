import React, { useEffect } from 'react';

// material-ui
import {
    Button,
    Grid,
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    CardContent,
    Tooltip,
    IconButton
} from '@mui/material';
import TableHeader from 'ui-component/TableHead';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// assets
import { getActiveBanks, fetchBankTransactionsByID } from 'utils/api';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Chip from 'ui-component/extended/Chip';
import { Edit } from '@mui/icons-material';
import Loader from 'ui-component/Loader';

// table header options
const headCells = [
    {
        id: 'createdAt',
        numeric: true,
        label: 'Date',
        align: 'left'
    },
    {
        id: 'amount',
        numeric: false,
        label: 'Amount',
        align: 'left'
    },
    {
        id: 'utr',
        numeric: true,
        label: 'UTR',
        align: 'left'
    },
    {
        id: 'is_get',
        numeric: false,
        label: 'Is Get',
        align: 'left'
    },
    {
        id: 'desc',
        numeric: true,
        label: 'Desc',
        align: 'left'
    },
    {
        id: 'account',
        numeric: true,
        label: 'Account',
        align: 'left'
    },
    {
        id: 'method',
        numeric: true,
        label: 'Pay Method',
        align: 'left'
    },
    {
        id: 'mode',
        numeric: true,
        label: 'Mode',
        align: 'left'
    },
    {
        id: 'txn_date',
        numeric: true,
        label: 'TXN Date',
        align: 'left'
    }
];

const PayinDetails = () => {
    const { id } = useParams();

    const dispatch = useDispatch();

    const [rows, setRows] = React.useState([]);
    const [allData, setAllData] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const [selectedBank, setSelectedBank] = React.useState();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);

    const fetchDataFromAPI = async (page, rowsPerPage) => {
        setLoading(true);
        try {
            const result = await fetchBankTransactionsByID(id, page, rowsPerPage);
            setLoading(false);
            setAllData(result?.data);
            setRows(result?.data?.transactionList || []);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
        try {
            let result = await getActiveBanks();
            result = result.map(({ id, bankName, accountHolderName }) => ({
                value: id,
                label: bankName,
                name: accountHolderName
            }));
            setSelectedBank(result.filter((r) => r?.value === id)[0]);
        } catch (error) {
            console.error(error);
        }
    };

    React.useEffect(() => {
        if (!page && !rowsPerPage) return;
        fetchDataFromAPI(page, rowsPerPage);
    }, [id, page, rowsPerPage]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target.value, 10));
        setPage(0);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleCloseDialog = () => {
        setOpen(false);
        fetchDataFromAPI(page, rowsPerPage);
    };

    return (
        <MainCard title={<div>{id}</div>} content={false}>
            {loading && <Loader />}
            <CardContent>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={8} sm={8} alignItems="center" spacing={2} />
                    {/* <div style={{ fontSize: 14, fontWeight: 500 }}>Live Balance: ₹ {allData?.liveBalance || 0}</div>
                    </Grid> */}
                    <Grid item xs={4} sm={4} style={{ textAlign: 'right' }} alignItems="end" justifyContent="end">
                        <Button disabled={loading} variant="contained" onClick={() => setOpen(true)}>
                            Add Payin Meta
                        </Button>
                        {/* <AddTransaction id={id} open={open} handleCloseDialog={handleCloseDialog} /> */}
                    </Grid>
                </Grid>
            </CardContent>
            <TableContainer sx={{ mt: 2, height: '80vh' }}>
                <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                    <TableHeader columns={headCells} />
                    <TableBody>
                        {rows?.map((row, index) => {
                            /** Make sure no display bugs if row isn't an OrderData object */
                            if (typeof row === 'number') return null;
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                                <TableRow hover>
                                    <TableCell component="th" id={labelId} scope="row">
                                        <div style={{ width: 140, fontWeight: 500 }}>
                                            {row.createdAt ? new Date(row.createdAt).toLocaleString() : ''}
                                        </div>
                                    </TableCell>
                                    <TableCell>{row.amount}</TableCell>
                                    <TableCell>
                                        <div style={{ width: 100 }}>{row.transactionUTR}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 40 }}>{row.isGet}</div>
                                    </TableCell>
                                    <TableCell>{row.description}</TableCell>
                                    <TableCell>
                                        <div style={{ width: 200 }}>{row.accountHolderName}</div>
                                        <div>{row.accountNumber}</div>
                                        <div style={{ fontSize: 12 }}>{row.bankName}</div>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 80 }}>{row.payMethod}</div>
                                    </TableCell>
                                    <TableCell>
                                        <Chip label={row.mode} />
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ width: 80 }}>{row.txnDate}</div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip placement="top" title="Edit">
                                            <IconButton color="primary" aria-label="edit" size="large">
                                                <Edit onClick={() => setOpen(row)} sx={{ fontSize: '1.1rem' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 53 * emptyRows
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
                rowsPerPageOptions={[50, 100, 250]}
                component="div"
                count={allData?.totalSize}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </MainCard>
    );
};

export default PayinDetails;

import { SuperAdminDashboard, AdminDashboard, AgentDashboard } from './dashboard';
import { SuperAdminPayment, AdminPayment, AgentPayment } from './payment';
import support, { AgentSupport } from './support';
import Developer from './developer';

// ==============================|| MENU ITEMS ||============================== //

export const SuperAdminMenuItems = {
    items: [SuperAdminDashboard, SuperAdminPayment, support]
};
export const AdminMenuItems = {
    items: [AdminDashboard, AdminPayment, support]
};
export const AgentMenuItems = {
    items: [AgentDashboard, AgentPayment, AgentSupport]
};
export const DeveloperMenuItems = {
    items: [Developer]
};

export default SuperAdminMenuItems;
